.ButtonAdd {
    background-color: rgb(206, 0, 120);
    background-image: url('../../../assets/add.png') ;
    border: none;
    color: white;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
    font-size: 50px;
    font-weight: 500;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: fixed; 
    right: 40px; 
    top: 60px; 
    -webkit-box-shadow: 5px 5px 10px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 10px 2px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 10px 2px rgba(0,0,0,0.75);
    z-index:100;
  }

  .ButtonAdd:hover {
    background-color: rgb(243, 39, 151);
  }
 


@media (max-width: 499px) {
    
    .ButtonAdd {
        background-image: url('../../../assets/add_mobile.png') ;
        font-size: 50px;
        vertical-align: text-top;
        font-weight: 500;
        font-size: 50px;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        position: fixed; 
        top: 70px; 
        right: 10px; 
        
      }
}