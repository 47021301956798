

@media (min-width: 500px) {

    .Titolo {
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
        
    }

    @media (min-width: 500px) {
        .TitoloCell {
            display: none;
        }
    }
        
    }



.TotaleGiornaliero{
    font-weight: 400;
    font-size: 20px;
}

.Descrizione{
    font-size: 20px;
    width: 300px;
    font-size: 15px;
}

.Chiusura {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    border-bottom: rgb(240, 240, 240) 1px solid;
    padding-left: 5px;
    min-width: 300px;
}



.form-control {
    width: 100px;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
}

@media (max-width: 499px) {

    .Titolo {
        display: none;
    }
    .TitoloCell {
        font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding-left: 10px;
    
    }

    .TotaleGiornaliero{
        font-weight: 400;
        font-size: 15px;
    }

    .Chiusura {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 300;
        text-align: left;
        border-bottom: rgb(240, 240, 240) 0.5px solid;
        padding-left: 5px;
        min-width: 300px;
    }

    th {   font-size: 0.8rem;
            padding: 0.2rem !important;
    }


}

.ContenitoreVendite {
    width: 100%;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;

}

.ContenitoreVendite ul{
    padding: 0;
    margin: 0;
}

.ContenitoreVendite ul li{
    list-style: none;
    float: left;
    min-width: 20%;
    min-height: 100px;
    margin: 5px ;
    padding: 5px;
    
}

