.NavigationBar {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color:white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    z-index: 90;
    /* box-shadow: 0px 1px 10px 1px #CACFD2; */
    box-shadow: 0px 2px 5px 0px #888888;
}

.NavigationBar nav {
    height: 100%;
    
}

.Logo {
    height: 80%;
}

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
    cursor: pointer; 

}



@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}

@media (min-width: 500px) {
    .MobileOnly {
        display: none;
    }
}