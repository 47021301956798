.Vendita {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    border-bottom: rgb(240, 240, 240) 0.5px solid;
    padding-left: 5px;
    min-width: 300px;
}

.ImmagineVendita {
    width: 100px;
}

.PrezziVendita {
    min-width: 130px;
    text-align: right;
    font-weight: 300;
}

@media (max-width: 499px) {
    .Vendita {
        font-size: 20px;
        max-width: 100%;
    }

    .ImmagineVendita {
        width: 50px;
    }

    .Vendita {
        font-family: 'Roboto', sans-serif;
        font-size: 10px;
        font-weight: 300;
        text-align: left;
        border-bottom: rgb(240, 240, 240) 0.5px solid;
        padding-left: 5px;
        min-width: 300px;
    }

    .PrezziVendita {
        min-width: 60px;
        text-align: right;
        font-weight: 300;
    }


}