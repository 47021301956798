

@media (min-width: 500px) {

    .Titolo {
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
        float: left;
    }

    @media (min-width: 500px) {
        .TitoloCell {
            display: none;
        }
    }
        
    }



.TotaleGiornaliero{
    font-weight: 400;
    font-size: 20px;
}

.Descrizione{
    font-size: 20px;
    width: 300px;
    font-size: 15px;
}

@media (max-width: 499px) {

    .Titolo {
        display: none;
    }
    .TitoloCell {
        font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding-left: 10px;
    float: left;
    }

    .TotaleGiornaliero{
        font-weight: 400;
        font-size: 15px;
    }
}

.ContenitoreVendite {
    width: 100%;
    overflow: hidden;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;

}

.ContenitoreVendite ul{
    padding: 0;
    margin: 0;
}

.ContenitoreVendite ul li{
    list-style: none;
    float: left;
    min-width: 20%;
    min-height: 100px;
    margin: 5px ;
    padding: 5px;
    
}

