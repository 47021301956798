
.titoloTipoVendita {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    border-bottom: lightgray 1px solid;
    padding-left: 5px;
    max-width: 800px;
}

.intestazioneVendita{
    text-align: left;
    font-family: 'Roboto', sans-serif;
    border-bottom: lightgray 1px solid;
    font-size: 10px;
    font-weight: 200;
}



.tableVendite, td, tbody{
    font-family: 'Roboto', sans-serif;
    
    font-size: 1.5rem;
    font-weight: 300;
    text-align: left;
    border-bottom: lightgray 1px solid;
    padding-left: 5px;
}


.th{
    font-family: 'Roboto', sans-serif;
    
    font-size: 25px;
    font-weight: 300;
    text-align: left;
    border-bottom: lightgray 1px solid;
    padding-left: 5px;
}

.Descrizione{
    font-size: 15px;
    min-width: 190px;
}


.Prezzi {
    min-width: 130px;
    text-align: right;
    font-weight: 400;
    font-size: 20px;
}

.Card {
    position: relative;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    min-width: 600px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 5px ;
}

.CardHeader {
    text-align: left;
    padding: 0.1rem 1.25rem;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.CardBody {
    text-align: left;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem 0.2rem;
}

.CardFooter {
    text-align: right;
    font-weight: 500;
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}



@media (max-width: 499px) {
    .TitoloTipoVendita {
        font-size: 20px;
        width: 100%;
    }

    .Descrizione{
        font-size: 20px;
        width: 300px;
        font-size: 15px;
    }

    .Card {
        
        position: relative;
        display: flex;
        flex: 1 0;
        flex-direction: column;
        min-width: 200px;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        margin: 5px ;
    }

    .CardBody {
        text-align: left;
        flex: 1 1 auto;
        min-height: 1px;
        
    }


        .tableVendite, td, tbody{
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-weight: 300;
            text-align: left;
            border-bottom: lightgray 1px solid;
            padding-left: 5px;
        }

        .Prezzi {
            min-width: 60px;
            text-align: right;
            font-weight: 400;
            font-size: 15px;
        }


}