.table td {
    padding: 0px;
    


}

th {
    padding: 0px;
    font-size: 1.8rem ;
 

}

