.NuovaVenditaForm {

    font-size: 20px;
        padding: 15px;
        height:100%;
}


.TipoVendita{
    padding: 20px;
}

@media (max-width: 768px) {
    .BtnResponsive {
      padding:2px 4px;
      font-size:80%;
      line-height: 1;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .BtnResponsive {
      padding:4px 9px;
      font-size:90%;
      line-height: 1.2;
    }
  }

  
.rosso {
    height: 80px;
    line-height: 70px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #f7c5c0;
	background:linear-gradient(to bottom, #fc8d83 5%, #e4685d 100%);
	background-color:#fc8d83;
	border-radius:8px;
	border:1px solid #d83526;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #b23e35;
}
.rosso:hover {
	background:linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
	background-color:#e4685d;
}

.azzurro {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 220px;
	box-shadow: 1px 3px 5px 0px #bee2f9;
	background:linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
	background-color:#63b8ee;
	border-radius:8px;
	border:1px solid #3866a3;
	display:inline-block;
	cursor:pointer;
	color:#ffffff; 
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #7cacde;
}
.azzurro:hover {
	background:linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
	background-color:#468ccf;
}

.giallo {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #fff6af;
	background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
	background-color:#ffec64;
	border-radius:8px;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:#333333;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffee66;
}
.giallo:hover {
	background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	background-color:#ffab23;
}

.purple {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #a8a8a8;
	background:linear-gradient(to bottom, #e64e8b 5%, #cf3a6b 100%);
	background-color:#e64e8b;
	border-radius:8px;
	border:1px solid #ab1960;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #662828;
}
.purple:hover {
	background:linear-gradient(to bottom, #cf3a6b 5%, #e64e8b 100%);
	background-color:#cf3a6b;
}

.grigiochiaro {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius:8px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.grigiochiaro:hover {
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	background-color:#dfdfdf;
}

.acqua {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #276873;
	background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
	background-color:#599bb3;
	border-radius:8px;
	border:1px solid #29668f;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #3d768a;
}
.acqua:hover {
	background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
	background-color:#408c99;
}

.violetto {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #efdcfb;
	background:linear-gradient(to bottom, #dfbdfa 5%, #bc80ea 100%);
	background-color:#dfbdfa;
	border-radius:8px;
	border:1px solid #c584f3;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #9752cc;
}
.violetto:hover {
	background:linear-gradient(to bottom, #bc80ea 5%, #dfbdfa 100%);
	background-color:#bc80ea;
}

.verde {
    height: 80px;
    line-height: 70px;
    margin: 10px;
	width: 200px;
	box-shadow: 1px 3px 5px 0px #9acc85;
	background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
	background-color:#74ad5a;
	border-radius:8px;
	border:1px solid #3b6e22;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding: 5px 5px;
	text-decoration:none;
	text-shadow:0px 1px 0px #92b879;
}
.verde:hover {
	background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
	background-color:#68a54b;
}


@media  (max-width: 768px) {
    .rosso,
    .giallo,
    .purple,
    .grigiochiaro,
    .acqua,
    .violetto,
    .verde {
        
    line-height: 50px;
        margin: 5px;
        width: 85px;
        font-size:12px;
        height: 60px;
    }
    
 
    .azzurro {
        line-height: 50px;
        margin: 5px;
        width: 180px;
        height: 60px;
        font-size:12px;
    }
  }

