.Content {
    margin-top: 72px;
}

.formAuth {
    max-width: 400px;
    padding: 30px;
    margin: auto;

}

@media  (max-width: 499px) {

    .formAuth {
        width: 80%;
        padding: 30px;
    
    }
}