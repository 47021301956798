.table td {
    padding: 0px;
  }

th {
    padding: 0px;
    font-size: 1.8rem ;
 

}

@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}

@media (min-width: 500px) {
    .MobileOnly {
        display: none;
    }
}



